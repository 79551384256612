export default function playerLoader(
  endpoints, shadow
) {

  return new Promise((resolve, reject) => {

    const loaders = endpoints.map((entryPoint) => {
      if (entryPoint.includes("js")) {
        return loadScript(entryPoint);
      } else if (entryPoint.includes("css")) {
        return loadStyle(entryPoint);
      } else {
        return undefined;
      }
    });

    Promise.all(loaders).then(() => {
      resolve();
    })
    .catch(e => {
      reject(e)
    })
  })

  function loadScript(scriptURL) {
    return new Promise((resolve, reject) => {
      const aScript = document.createElement("script");
      aScript.type = "text/javascript";
      aScript.src = scriptURL;
     
      shadow.appendChild(aScript);
  
      aScript.onload = function () {
        resolve();
      };
  
      aScript.onerror = function (e) {  
        reject("Script error");
      };
    });
  }
  
  function loadStyle(styleURL) {
    return new Promise((resolve, reject) => {
      const aStyle = document.createElement("link");
      aStyle.type = "text/css";
      aStyle.rel = "stylesheet";
      aStyle.href = styleURL;
  
      shadow.appendChild(aStyle);
      
      aStyle.onload = function () {
        resolve();
      };
  
      aStyle.onerror = function (e) {
        reject("Style error");
      };
    });
  }
};
